<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>관리자 - 사용자 권한그룹 관리</h1>
      </v-col>
      <v-col cols="auto" class="ml-auto">
        <v-btn color="#FF4D00" dark block @click="$router.push('/admin')">
          BACK
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-toolbar color="#083272" dark>
            <v-toolbar-title>
              <h3 class="font-weight-bold suptitle">User Group List</h3>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="white" light @click="newAccountBtn">
              <v-icon>mdi-plus</v-icon>
              New Group
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-data-table
              :headers="accountHeader"
              :items="accountList"
              class="elevation-0"
            >
              <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2" @click="editItem(item)">
                  mdi-pencil
                </v-icon>
                <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
              </template>
              <template v-slot:item.Email="{ item }">
                {{ item.user_mail_id }}@{{ item.user_mail_domain }}
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title>
          <h5>삭제시 복구가 불가능 합니다.<br />정말 삭제 하시겠습니까?</h5>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="deleteItemConfirm">
            삭제
          </v-btn>
          <v-btn color="blue darken-1" text @click="closeDelete">취소</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogNewAccount" max-width="800px">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar color="#083272" dark>
            <v-toolbar-title>
              <h3 class="font-weight-bold suptitle">New User Account</h3>
            </v-toolbar-title>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-container class="py-6">
            <v-row class="pb-2">
              <v-col cols="12"><h2>Account Info</h2></v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="pt-2">
              <v-col cols="12">
                <v-text-field
                  outlined
                  light
                  dense
                  hide-details
                  label="USER ID"
                  class="px-1 pt-0"
                  v-model="user_frm.user_id"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  light
                  dense
                  hide-details
                  label="PASSWORD"
                  class="px-1 pt-0"
                  append-icon="mdi-auto-fix"
                  v-model="user_frm.user_pw"
                  @click:append="passAutoFill"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="pb-2">
              <v-col cols="12"><h2>User Info</h2></v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="pt-2">
              <v-col cols="6">
                <v-text-field
                  outlined
                  light
                  dense
                  hide-details
                  label="UESR FIRST NAME(KOR:이름)"
                  class="px-1 pt-0"
                  v-model="user_frm.user_name_first_kor"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  outlined
                  light
                  dense
                  hide-details
                  label="UESR LAST NAME(KOR:성)"
                  class="px-1 pt-0"
                  v-model="user_frm.user_name_last_kor"
                >
                </v-text-field>
              </v-col>

              <v-col cols="6">
                <v-text-field
                  outlined
                  light
                  dense
                  hide-details
                  label="UESR FIRST NAME(ENG:이름)"
                  class="px-1 pt-0"
                  v-model="user_frm.user_name_first_eng"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  outlined
                  light
                  dense
                  hide-details
                  label="UESR LAST NAME(ENG:성)"
                  class="px-1 pt-0"
                  v-model="user_frm.user_name_last_eng"
                >
                </v-text-field>
              </v-col>

              <v-col cols="6">
                <v-text-field
                  outlined
                  light
                  dense
                  hide-details
                  label="MAIL ID"
                  class="px-1 pt-0"
                  v-model="user_frm.user_mail_id"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  outlined
                  light
                  dense
                  hide-details
                  label="MAIL DOMAIN"
                  class="px-1 pt-0"
                  prepend-inner-icon="mdi-at"
                  v-model="user_frm.user_mail_domain"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  light
                  dense
                  hide-details
                  label="USER PHONE NUMBER"
                  class="px-1 pt-0"
                  v-model="user_frm.user_phone_number"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="auto">
                <v-btn color="#FF4D00" dark block @click="newAccountSaveBtn">
                  SAVE
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogEditAccount" max-width="800px">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar color="#083272" dark>
            <v-toolbar-title>
              <h3 class="font-weight-bold suptitle">Edit User Account</h3>
            </v-toolbar-title>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-container class="py-6">
            <v-row class="pb-2">
              <v-col cols="12"><h2>Account Info</h2></v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="pt-2">
              <v-col cols="12">
                <v-text-field
                  outlined
                  light
                  dense
                  hide-details
                  label="USER ID"
                  class="px-1 pt-0"
                  v-model="edit_user_frm.user_id"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  light
                  dense
                  hide-details
                  label="PASSWORD"
                  class="px-1 pt-0"
                  append-icon="mdi-auto-fix"
                  v-model="edit_user_frm.user_pw"
                  @click:append="passAutoFill"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="pb-2">
              <v-col cols="12"><h2>User Info</h2></v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="pt-2">
              <v-col cols="6">
                <v-text-field
                  outlined
                  light
                  dense
                  hide-details
                  label="UESR FIRST NAME(KOR:이름)"
                  class="px-1 pt-0"
                  v-model="edit_user_frm.user_name_first_kor"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  outlined
                  light
                  dense
                  hide-details
                  label="UESR LAST NAME(KOR:성)"
                  class="px-1 pt-0"
                  v-model="edit_user_frm.user_name_last_kor"
                >
                </v-text-field>
              </v-col>

              <v-col cols="6">
                <v-text-field
                  outlined
                  light
                  dense
                  hide-details
                  label="UESR FIRST NAME(ENG:이름)"
                  class="px-1 pt-0"
                  v-model="edit_user_frm.user_name_first_eng"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  outlined
                  light
                  dense
                  hide-details
                  label="UESR LAST NAME(ENG:성)"
                  class="px-1 pt-0"
                  v-model="edit_user_frm.user_name_last_eng"
                >
                </v-text-field>
              </v-col>

              <v-col cols="6">
                <v-text-field
                  outlined
                  light
                  dense
                  hide-details
                  label="MAIL ID"
                  class="px-1 pt-0"
                  v-model="edit_user_frm.user_mail_id"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  outlined
                  light
                  dense
                  hide-details
                  label="MAIL DOMAIN"
                  class="px-1 pt-0"
                  prepend-inner-icon="mdi-at"
                  v-model="edit_user_frm.user_mail_domain"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  light
                  dense
                  hide-details
                  label="USER PHONE NUMBER"
                  class="px-1 pt-0"
                  v-model="edit_user_frm.user_phone_number"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="auto">
                <v-btn color="#FF4D00" dark block> SAVE </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { getAccounts, delAccount, newAccount } from "@/api/Admin/Accounts";
import randomstring from "randomstring";
export default {
  mounted() {
    this.getAccounts();
  },
  methods: {
    async getAccounts() {
      const data = await getAccounts();
      this.accountList = [];
      if (data.success) {
        this.accountList = data.data;
      }
    },
    deleteItem(item) {
      this.selectedItem = item;
      this.dialogDelete = true;
    },
    closeDelete() {
      this.dialogDelete = false;
      this.selectedItem = null;
    },
    async deleteItemConfirm() {
      await delAccount(this.selectedItem.user_uuid);
      this.dialogDelete = false;
      this.selectedItem = null;
      this.getAccounts();
    },
    newAccountBtn() {
      this.user_frm = {
        user_idx: null,
        user_id: null,
        user_pw: null,
        user_name_first_kor: null,
        user_name_last_kor: null,
        user_name_first_eng: null,
        user_name_last_eng: null,
        user_mail_id: null,
        user_mail_domain: null,
        user_phone_number: null,
      };
      this.dialogNewAccount = true;
    },
    async newAccountSaveBtn() {
      await newAccount(this.user_frm);
      this.dialogNewAccount = false;
      await this.getAccounts();
    },
    passAutoFill() {
      const str = randomstring.generate(7);
      this.user_frm.user_pw = str;
    },
    editItem(item) {
      this.edit_user_frm = item;
      this.edit_user_frm.user_pw = null;
      this.dialogEditAccount = true;
    },
  },
  data() {
    return {
      accountList: [],
      accountHeader: [
        {
          text: "ID",
          align: "start",
          sortable: true,
          value: "user_id",
        },
        {
          text: "이름",
          align: "start",
          sortable: false,
          value: "user_name_first_kor",
        },
        {
          text: "성",
          align: "start",
          sortable: false,
          value: "user_name_last_kor",
        },
        {
          text: "전화번호",
          align: "start",
          sortable: false,
          value: "user_phone_number",
        },
        { text: "Email", value: "Email", sortable: false },
        {
          text: "권한",
          align: "start",
          sortable: false,
          value: "user_level",
        },
        { text: "Actions", value: "actions", sortable: false },
      ],
      selectedItem: null,

      dialogDelete: false,
      dialogNewAccount: false,
      dialogEditAccount: false,

      user_frm: {
        user_idx: null,
        user_id: null,
        user_pw: null,
        user_name_first_kor: null,
        user_name_last_kor: null,
        user_name_first_eng: null,
        user_name_last_eng: null,
        user_mail_id: null,
        user_mail_domain: null,
        user_phone_number: null,
      },
      edit_user_frm: {
        user_idx: null,
        user_id: null,
        user_pw: null,
        user_name_first_kor: null,
        user_name_last_kor: null,
        user_name_first_eng: null,
        user_name_last_eng: null,
        user_mail_id: null,
        user_mail_domain: null,
        user_phone_number: null,
      },
    };
  },
};
</script>
